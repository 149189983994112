import axios, { AxiosError, AxiosResponse } from 'axios';
import {
    IResponseData,
    IMagicLinkResponseData,
    IOrderModalAppShellPayLoad,
    IOrderModalMagicLinkPayLoad,
} from './types';

class Fetcher {
    public readonly magicLink: string;
    private readonly loggingEnabled: boolean;
    private readonly apiUrl: string;
    private readonly magicLinkApiUrl: string;
    private readonly basketApiUrl: string;

    constructor(magicLink: string, enabledClientLogging?: boolean) {
        this.magicLink = magicLink;
        this.loggingEnabled = enabledClientLogging;
        this.apiUrl = this.magicLink + 'api/ordermodal/OrderModalData';
        this.magicLinkApiUrl = this.magicLink + 'api/ordermodal/OrderModalDataWithMagicLink';
        this.basketApiUrl = this.magicLink + 'api/basket/Basket/BasketItemQuantities';
        this.handleError.bind(this);
    }

    public getOrderModalData(
        appShellPayload: IOrderModalAppShellPayLoad,
        onSuccess?: (data: IResponseData) => void,
        onFailure?: (error: Error | AxiosError) => void,
        onFinally?: () => void
    ): void {
        const requestUrl = new URL(this.apiUrl);
        requestUrl.searchParams.set('salesArticleNo', appShellPayload.salesArticleNo);
        requestUrl.searchParams.set('masterArticleNo', String(appShellPayload.masterArticleNo));
        requestUrl.searchParams.set('colorCode', String(appShellPayload.colorCode));
        if (appShellPayload.sizeCode) requestUrl.searchParams.set('sizeCode', String(appShellPayload.sizeCode));

        axios
            .get(requestUrl.toString(), { timeout: 5000 })
            .then((response: AxiosResponse) => {
                const responseData = response.status === 200 ? response.data : undefined;
                typeof onSuccess === 'function' && onSuccess(responseData);
                return responseData;
            })
            .catch((error: Error | AxiosError) => {
                const result = this.handleError(error);
                typeof onFailure === 'function' && onFailure(error);
                return result;
            })
            .finally(() => {
                typeof onFinally === 'function' && onFinally();
            });
    }

    public getOrderModalDataViaMagicLink(
        appShellPayload: IOrderModalMagicLinkPayLoad,
        onSuccess?: (data: IMagicLinkResponseData) => void,
        onFailure?: (error: Error | AxiosError) => void,
        onFinally?: () => void
    ): void {
        const requestUrl = new URL(this.magicLinkApiUrl);
        requestUrl.searchParams.set('magicLink', appShellPayload.magicLink);

        axios
            .get(requestUrl.toString(), { timeout: 5000 })
            .then((response: AxiosResponse) => {
                const responseData = response.status === 200 ? response.data : undefined;
                typeof onSuccess === 'function' && onSuccess(responseData);
                return responseData;
            })
            .catch((error: Error | AxiosError) => {
                const result = this.handleError(error);
                typeof onFailure === 'function' && onFailure(error);
                return result;
            })
            .finally(() => {
                typeof onFinally === 'function' && onFinally();
            });
    }

    public getBasketItemCount(
        savKey: string,
        onSuccess?: (itemCount: number) => void,
        onFailure?: () => void,
        onFinally?: () => void
    ): void {
        const requestUrl = new URL(this.basketApiUrl);
        requestUrl.searchParams.set('savKeyList', savKey);

        axios
            .get(requestUrl.toString())
            .then((response: AxiosResponse) => {
                let itemCount = 1;
                if (response.status !== 200 || !response.data) return itemCount;
                //service can handle multiple but we only care about one
                const savKeyToCount: { [savKey: string]: number } = response.data;

                if (!(savKey in savKeyToCount)) return itemCount;
                itemCount = savKeyToCount[savKey];
                typeof onSuccess === 'function' && onSuccess(itemCount);

                return savKeyToCount[savKey];
            })
            .catch((error) => {
                typeof onFailure === 'function' && onFailure();
                return this.handleError(error);
            })
            .finally(() => {
                typeof onFinally === 'function' && onFinally();
            });
    }

    setPriceModeCookie(newPriceMode: 'Net' | 'Gross') {
        const urlToHost = this.magicLink.replace('api/', '');
        const apiUrl = urlToHost + 'ajax/update/Content/Empty2';
        const requestURL = new URL(apiUrl);

        requestURL.searchParams.set('PriceMode', newPriceMode);
        axios
            .get(requestURL.toString())
            .then((res: AxiosResponse) => {
                if (res.status === 200 && typeof window !== 'undefined') {
                    window.shell.publishTo('PriceModeChanged', newPriceMode);
                }
            })
            .catch((error: Error | AxiosError) => {
                return this.handleError(error);
            });
    }

    private handleError(error: Error | AxiosError) {
        if (this.loggingEnabled) {
            const errorMsg = this.isAxiosError(error) ? error.message : `${error.name}: ${error.message}`;
            const errorStack = this.isAxiosError(error) ? JSON.stringify(error.toJSON()) : error.stack;
            // eslint-disable-next-line no-console
            console.warn(`ajax fetch failed with "${errorMsg}". Full error:\n${errorStack}`);
        }
        return error;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private isAxiosError(error: any): error is AxiosError {
        return typeof error.toJSON !== 'undefined';
    }
}

export default Fetcher;
