import * as React from 'react';
import { configure } from 'mobx';
import { Provider } from 'mobx-react';
import { IOrderModalContentProps } from '../../../types';
import { ViewType } from '../../../stores/types';
import { MainStore } from '../../../stores/MainStore';
import { IResponseData } from '../../../stores/types';
import OrderModalContent from '../orderModalContent/orderModalContent';

configure({ enforceActions: 'observed', isolateGlobalState: true, useProxies: 'always' });

export const OrderModalDataProvider = ({
    isLoading,
    initialData,
    initialScrollPosition,
    handleIsOpenChange,
    itemOrigin,
    magicLink,
    enabledClientLogging,
    contentContainerId,
    viewType,
    slidingOut,
}: IOrderModalContentProps & IOrderModalDataProvider) => {
    if (isLoading || !initialData) {
        return null;
    }

    if (!itemOrigin) itemOrigin = '';
    const store = React.useMemo(() => new MainStore(initialData, __OMI18n__, itemOrigin, magicLink,
        enabledClientLogging, initialScrollPosition, contentContainerId, viewType), []);

    return (
        <Provider
            store={store}
            orderModuleStore={store.orderModuleStore}
            dataStore={store.dataStore}
            mixedMediaStore={store.mixedMediaStore}
            variantStore={store.variantStore}
        >
            <OrderModalContent handleIsOpenChange={handleIsOpenChange} viewType={viewType} slidingOut={slidingOut} />
        </Provider>
    );
};

interface IOrderModalDataProvider {
    isLoading: boolean;
    initialData: IResponseData;
    initialScrollPosition: number;
    itemOrigin: string;
    magicLink: string;
    enabledClientLogging: boolean;
    contentContainerId: string;
    viewType?: ViewType;
    slidingOut?: boolean;
    handleIsOpenChange(isOpen: boolean);
}

export default OrderModalDataProvider;
